<template>
    <section class="content">
        <div class="container-fluid">
             <!-- Main row -->
            <div class="row">
                <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                    <div class="card">
                        <div class="card-body table-responsive table-head">
                            <div class="row bor-bot">
                                <div class="col-md-8 pl-0 mb-3">
                                    <h1 class="m-0 text-dark pt-2 pb-2">Member Registration Reports</h1>
                                </div>
                                <div class="col-md-4">  
                                    <div class="text-right pb-3">
                                        <ol class="breadcrumb float-sm-right">
                                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                                        </ol>
                                    </div>   
                                </div>
                            </div>
                            <div class="table-responsive mt-3" id="member_reg_report_table">
                                <div class="data-tablebutn pb-3 ">
                                    <b-button class="btn-primary btn-darkblue btn mr-1" :class="{ 'filter-applied': filtersApplied }" v-b-modal.modal-member-report>
                                        <i class="fas fa-sliders-h"></i> Filters
                                    </b-button>
                                    <b-button class="btn-primary btn-darkblue btn" @click="refreshList" v-if="filtersApplied">
                                        <i class="fas fa-redo"></i> Reset
                                    </b-button>
                                    <b-button class="ml-1 btn-primary btn-darkblue btn" @click="exportCsv" :disabled="exportProgress">
                                        <i class="fas" :class="[ exportProgress ? 'fa-circle-notch fa-spin' : 'fa-file-download' ]"></i> Export
                                    </b-button>
                                </div>
                                <div class="search-box">
                                    <input type="text" align="right" class="input-field form-control" v-model="searchValue" style="width:100%; max-width:234px" name="general_search" id="general_search" @keyup="queryForKeywords">
                                    <i class="fas fa-search" style="position:absolute"></i>
                                </div>
                                <table class="table table-sm table-bordered table-hover" id="list_table">
                                    <thead class="thead-dark">
                                    <tr>
                                        <th>Member Id</th>
                                        <th>Name</th>
                                        <th>E-Mail</th>
                                        <th>Discipline</th>
                                        <th>State</th>
                                        <th>DOB</th>
                                        <th>Magazine</th>
                                        <th>Membership</th>
                                        <th>Effective From</th>
                                        <th>Effective To</th>
                                        <th>Auto-Renew</th>
                                        <th>Status</th>
                                        <th class="none">Transaction Id</th>
                                        <th>Date Paid</th>
                                        <th class="none">Groups</th>
                                        <th class="none">Tags</th>
                                        <th class="none">Volunteering</th>
                                        <th class="none">Undeliverable Address</th>
                                        <th class="none">Team Id</th>
                                        <th class="none">Team Name</th>
                                        <th class="none">League</th>
                                        <th class="none">Age Division</th>
                                        <th class="none">Recruiting</th>
                                        <th class="none">Citizenship</th>
                                        <th class="none">Waiver Status</th>
                                        <th class="none">Waiver Signed On</th>
                                        <th class="none">Waiver Expiration On</th>
                                        <th class="none">Coupon Code</th>
                                        <th class="none">Veteran Status</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <!-- Data will be placed here -->
                                    <tr>
                                        <td colspan="9">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colspan="9">&nbsp;</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                             <b-modal id="modal-member-report" v-bind:hide-footer="true" ref="modal-member-report" size="xl"  title="Advanced Search">
                                 <p style="margin-top: -20px;margin-bottom: 30px "><strong class="text-primary">Note:</strong> <span class=" text-muted">Press Ctrl+click for multiple selection filters</span></p>
                                <form ref="form" method="post" @submit.prevent="advancedSearch()">
                                    <div class="row">
                                        <div class="col-md-3" v-if="this.getSiteProps('membership_reports.filters.member_id')">
                                            <div class="form-group">
                                                <label class="form-label">Member Id</label>
                                                <input type="text" id="confirmation_code" class="form-control" v-model="searchForm.confirmation_code">
                                            </div>
                                        </div>
                                        <div class="col-md-3" v-if="this.getSiteProps('membership_reports.filters.first_name')">
                                            <div class="form-group">
                                                <label class="form-label">First Name</label>
                                                <input type="text" id="first_name" class="form-control" v-model="searchForm.first_name">
                                            </div>
                                        </div>
                                        <div class="col-md-3" v-if="this.getSiteProps('membership_reports.filters.last_name')">
                                            <div class="form-group">
                                                <label class="form-label">Last Name</label>
                                                <input type="text" id="last_name" class="form-control" v-model="searchForm.last_name">
                                            </div>
                                        </div>
                                        <div class="col-md-3" v-if="this.getSiteProps('membership_reports.filters.email')">
                                            <div class="form-group">
                                                <label class="form-label">Email</label>
                                                <input type="text" id="email" class="form-control" v-model="searchForm.email">
                                            </div>
                                        </div>

                                        <div class="col-md-3" v-if="this.getSiteProps('membership_reports.filters.registration_from_date')">
                                            <div class="form-group">
                                                <label class="form-label">Registration From Date</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <date-pick
                                                            v-model="searchForm.registration_start_date"
                                                            :format="'MM/DD/YYYY'"
                                                            :displayFormat="'MM/DD/YYYY'"
                                                            v-mask="'##/##/####'"
                                                            :parseDate="parseDatePick"
                                                        ></date-pick>
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.registration_start_date }}</div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-3" v-if="this.getSiteProps('membership_reports.filters.registration_to_date')">
                                            <div class="form-group">
                                                <label class="form-label">Registration To Date</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <date-pick
                                                            v-model="searchForm.registration_end_date"
                                                            :format="'MM/DD/YYYY'"
                                                            :displayFormat="'MM/DD/YYYY'"
                                                            v-mask="'##/##/####'"
                                                            :parseDate="parseDatePick"
                                                        ></date-pick>
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.registration_end_date }}</div>
                                            </div>
                                        </div>

                                        <div class="col-md-3" v-if="this.getSiteProps('membership_reports.filters.expiration_from_date')">
                                            <div class="form-group">
                                                <label class="form-label">Expiration From Date</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <date-pick
                                                            v-model="searchForm.expiration_start_date"
                                                            :format="'MM/DD/YYYY'"
                                                            :displayFormat="'MM/DD/YYYY'"
                                                            v-mask="'##/##/####'"
                                                            :parseDate="parseDatePick"
                                                        ></date-pick>
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.expiration_start_date }}</div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-3" v-if="this.getSiteProps('membership_reports.filters.expiration_to_date')">
                                            <div class="form-group">
                                                <label class="form-label">Expiration To Date</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <date-pick
                                                            v-model="searchForm.expiration_end_date"
                                                            :format="'MM/DD/YYYY'"
                                                            :displayFormat="'MM/DD/YYYY'"
                                                            v-mask="'##/##/####'"
                                                            :parseDate="parseDatePick"
                                                        ></date-pick>
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.expiration_end_date }}</div>
                                            </div>
                                        </div>

                                        <div class="col-md-3" v-if="this.getSiteProps('membership_reports.filters.dob_from_date')">
                                            <div class="form-group">
                                                <label class="form-label">DOB From Date</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <date-pick
                                                            v-model="searchForm.dob_start_date"
                                                            :format="'MM/DD/YYYY'"
                                                            :displayFormat="'MM/DD/YYYY'"
                                                            v-mask="'##/##/####'"
                                                            :selectableYearRange="{from: 1900, to: 2022}"
                                                            :parseDate="parseDatePick"
                                                        ></date-pick>
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.dob_start_date }}</div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-3" v-if="this.getSiteProps('membership_reports.filters.dob_to_date')">
                                            <div class="form-group">
                                                <label class="form-label">DOB To Date</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <date-pick
                                                            v-model="searchForm.dob_end_date"
                                                            :format="'MM/DD/YYYY'"
                                                            :displayFormat="'MM/DD/YYYY'"
                                                            v-mask="'##/##/####'"
                                                            :selectableYearRange="{from: 1900, to: 2022}"
                                                            :parseDate="parseDatePick"
                                                        ></date-pick>
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.dob_end_date }}</div>
                                            </div>
                                        </div>

                                        <div class="col-md-3" v-if="this.getSiteProps('membership_reports.filters.age_from')">
                                            <div class="form-group">
                                                <label class="form-label">From Age</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <input type="number" id="from_age" class="form-control" v-model="searchForm.from_age" min="0">
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.from_age }}</div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-3" v-if="this.getSiteProps('membership_reports.filters.age_to')">
                                            <div class="form-group">
                                                <label class="form-label">To Age</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <input type="number" id="to_age" class="form-control" v-model="searchForm.to_age" min="0">
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.to_age }}</div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.dob')">
                                            <div class="form-group">
                                                <label class="form-label">Date of Birth</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <date-pick
                                                            v-model="searchForm.registration_dob"
                                                            :format="'MM/DD/YYYY'"
                                                            :displayFormat="'MM/DD/YYYY'"
                                                            v-mask="'##/##/####'"
                                                            :parseDate="parseDatePick"
                                                            :selectableYearRange="{from: 1900, to: 2022}"
                                                        ></date-pick>
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.registration_dob }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.gender')">
                                            <div class="form-group">
                                                <label class="form-label">Gender</label>
                                                <Select2 
                                                    v-model="searchForm.gender" 
                                                    name="gender" 
                                                    id="gender" 
                                                    :options="['Male', 'Female', 'Other', 'Prefer not to say']" 
                                                    :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" 
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.registration_year')">
                                            <div class="form-group">
                                                <label class="form-label">Registration Year</label>
                                                <select v-if="subdomain != 'alliancefastpitch'" id="year1" class="form-control" v-model="searchForm.year">
                                                    <option selected="selected" value="">Select</option>
                                                    <template v-for="year in years">
                                                        <option v-if="year >= 2018" :value="year" :key="'year' + year">{{ year }}</option>
                                                    </template>
                                                </select>
                                                <select id="season" v-if="subdomain == 'alliancefastpitch'" class="form-control" v-model="searchForm.season">
                                                  <option selected="selected" value="">Select</option>
                                                  <option v-for="(season, id) in seasons" :value="season.id" :key="id">{{ season.text }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.membership_type')">
                                            <div class="form-group">
                                                <label class="form-label">Membership Type</label>
                                                <Select2 
                                                    v-model="searchForm.membership_id" 
                                                    name="membership_id" 
                                                    id="membership_id" 
                                                    :options="membershipListSelect2"
                                                    :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" 
                                                />
                                            </div>
                                        </div>
                                        <div v-if="searchForm.membership_id.some(data => data == 5) && this.getSiteProps('membership_reports.filters.family_type')" class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-label">Family Type</label>
                                                <select 
                                                    v-model="searchForm.family_type" 
                                                    name="family_type" 
                                                    id="family_type"  class="form-control"
                                                >
                                                <option value=''>Select</option>
                                                <option value='0'>HOH</option>
                                                <option value='1'>Spouse</option>
                                                <option value='2'>Child</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.membership_status')">
                                            <div class="form-group">
                                                <label class="form-label">Membership Status</label>
                                                <Select2 
                                                    v-model="searchForm.status" 
                                                    name="status" 
                                                    id="status" 
                                                    :options="statusListSelect2"
                                                    :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" 
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.safesports_status')">
                                            <div class="form-group">
                                                <label class="form-label">Safesport Status</label>
                                              <Select2
                                                  v-model="searchForm.safe_sport_status"
                                                  name="safe_sport_status"
                                                  id="safe_sport_status"
                                                  :options="safe_sport_statuses"
                                                  :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}"
                                              />
                                            </div>
                                        </div>
                                      <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.safesports_expiration_from_date')">
                                        <div class="form-group">
                                          <label class="form-label">SafeSport Expiration From Date</label>
                                          <div class="input-group birthdate">
                                            <div class="birthdaypicker">
                                              <date-pick
                                                  v-model="searchForm.Safe_Sport_expiration_start_date"
                                                  :format="'MM/DD/YYYY'"
                                                  :displayFormat="'MM/DD/YYYY'"
                                                  v-mask="'##/##/####'"
                                                  :parseDate="parseDatePick"
                                              ></date-pick>
                                            </div>
                                          </div>
                                          <div class="text-sm text-danger">{{ errors.Safe_Sport_expiration_start_date }}</div>
                                        </div>
                                      </div>
                                      <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.safesports_expiration_to_date')">
                                        <div class="form-group">
                                          <label class="form-label">SafeSport Expiration To Date</label>
                                          <div class="input-group birthdate">
                                            <div class="birthdaypicker">
                                              <date-pick
                                                  v-model="searchForm.Safe_Sport_expiration_end_date"
                                                  :format="'MM/DD/YYYY'"
                                                  :displayFormat="'MM/DD/YYYY'"
                                                  v-mask="'##/##/####'"
                                                  :parseDate="parseDatePick"
                                              ></date-pick>
                                            </div>
                                          </div>
                                          <div class="text-sm text-danger">{{ errors.Safe_Sport_expiration_end_date }}</div>
                                        </div>
                                      </div>
                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.transaction_id')">
                                            <div class="form-group">
                                                <label class="form-label">Transaction Id</label>
                                                <input type="text" id="transaction_id" class="form-control" v-model="searchForm.transaction_id">
                                            </div>
                                        </div>

                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.transaction_from_date')">
                                            <div class="form-group">
                                                <label for="transaction_start_date" class="form-label">Transaction From Date</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <date-pick
                                                            v-model="searchForm.transaction_start_date"
                                                            :format="'MM/DD/YYYY'"
                                                            :displayFormat="'MM/DD/YYYY'"
                                                            v-mask="'##/##/####'"
                                                            :parseDate="parseDatePick"
                                                        ></date-pick>
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.transaction_start_date }}</div>
                                            </div>
                                        </div>

                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.transaction_to_date')">
                                            <div class="form-group">
                                                <label for="transaction_end_date" class="form-label">Transaction To Date</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <date-pick
                                                            v-model="searchForm.transaction_end_date"
                                                            :format="'MM/DD/YYYY'"
                                                            :displayFormat="'MM/DD/YYYY'"
                                                            v-mask="'##/##/####'"
                                                            :parseDate="parseDatePick"
                                                        ></date-pick>
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.transaction_end_date }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="subdomain != 'shooting' && this.getSiteProps('membership_reports.filters.primary_sport_discipline')">
                                            <div class="form-group">
                                                <label class="form-label">Primary Sport Discipline</label>
                                                <Select2 v-model="searchForm.discipline" name="discipline" id="discipline" :options="disciplines" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />

                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="subdomain != 'shooting' && this.getSiteProps('membership_reports.filters.country')">
                                            <div class="form-group">
                                                <label class="form-label">Country</label>
                                                <Select2 v-model="searchForm.country_id" @change="countryChange($event)" name="country" id="country" :options="countries" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />

                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="subdomain != 'shooting' && this.getSiteProps('membership_reports.filters.state')">
                                            <div class="form-group">
                                                <label class="form-label">State/Province</label>
                                                <Select2 v-model="searchForm.state_id" name="state_id" id="state_id" :options="states" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="searchForm.country_id.some(data => data != 'USA'&& data != 'Canada') && this.getSiteProps('membership_reports.filters.state')">
                                            <div class="form-group">
                                                <label class="form-label">Other State/Province</label>
                                                <input type="text" id="other_state" class="form-control" v-model="searchForm.other_state">
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="subdomain != 'shooting' && this.getSiteProps('membership_reports.filters.region') ">
                                            <div class="form-group">
                                                <label class="form-label">Region</label>
                                                <Select2 
                                                    v-model="searchForm.region_name" 
                                                    name="region_name" 
                                                    id="region_name" 
                                                    :options="regionsListSelect2" 
                                                    :settings="{ multiple: true, placeholder: 'All Regions'}"
                                                />
                                            </div>
                                        </div>

                                        <div class="col-md-4" v-if="subdomain == 'waterski' && this.getSiteProps('membership_reports.filters.printed_magazine')">
                                            <div class="form-group">
                                                <label class="form-label">Magazine</label>
                                                <select 
                                                    v-model="searchForm.printed_magazine" 
                                                    name="printed_magazine" 
                                                    id="printed_magazine"  class="form-control"
                                                >
                                                <option value=''>Select</option>
                                                <option value='0'>Subscribers</option>
                                                <option value='1'>Non Subscribers</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-4" v-if="subdomain == 'waterski' && this.getSiteProps('membership_reports.filters.federation')">
                                            <div class="form-group">
                                                <label class="form-label">Federation</label>
                                                <Select2 
                                                    v-model="searchForm.home_federation" 
                                                    name="home_federation" 
                                                    id="home_federation" 
                                                    :options="federationList"
                                                    :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="subdomain == 'waterski' && this.getSiteProps('membership_reports.filters.undeliverable_address')">
                                            <div class="form-group">
                                                <label class="form-label">Undeliverable Address</label>
                                                <select 
                                                    v-model="searchForm.undeliverable_address" 
                                                    name="undeliverable_address" 
                                                    id="undeliverable_address" 
                                                    class="form-control"
                                                >
                                                 <option value=''>Select</option>
                                                <option value='1'>Yes</option>
                                                <option value='0'>No</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-4" v-if="subdomain != 'alliancefastpitch'">
                                            <div class="form-group">
                                                <label class="form-label">Waiver Status</label>
                                                <select 
                                                    v-model="searchForm.waiver_status" 
                                                    name="waiver_status" 
                                                    id="waiver_status" 
                                                    class="form-control"
                                                >
                                                 <option value=''>Select</option>
                                                <option value='1'>Signed</option>
                                                <option value='0'>Not Signed</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="subdomain != 'alliancefastpitch'">
                                            <div class="form-group">
                                            <label class="form-label" v-if="subdomain == 'waterski'">Race</label>
                                            <label class="form-label" v-if="subdomain == 'shooting'">Ethnicity</label>
                                            <Select2 v-model="searchForm.diversity" name="diversity" id="diversity" :options="diversities" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="subdomain == 'waterski'">
                                            <div class="form-group">
                                                <label class="form-label">Ethnicity</label>
                                                <Select2 v-model="searchForm.ethnicity" name="ethnicity" id="ethnicity" :options="ethnicities" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />

                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="subdomain == 'waterski'">
                                            <div class="form-group">
                                                <label class="form-label">Disability Status</label>
                                                <Select2 v-model="searchForm.disability" name="disability" id="disability" :options="disabilities" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="subdomain == 'waterski'">
                                            <div class="form-group">
                                                <label class="form-label">Veteran Status</label>
                                                <Select2 v-model="searchForm.veteran_status" name="veteran_status" id="veteran_status" :options="veterans" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="getSiteProps('group_model.visible')">
                                            <div class="form-group">
                                            <label class="form-label">Groups</label>
                                            <Select2 v-model="searchForm.groups" name="groups" id="edit-groups" :options="groupList" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="getSiteProps('group_model.visible')">
                                            <div class="form-group">
                                            <label class="form-label">Tags</label>
                                            <Select2 v-model="searchForm.tags" name="tags" id="tags" :options="tags" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="getSiteProps('volunteering.visibility')">
                                            <div class="form-group">
                                            <label class="form-label">Volunteering</label>
                                            <select class="form-control" id="volunteering_roles" v-model="searchForm.volunteering_roles">
                                                <option value="">Select a Role</option>
                                                <option v-for="(role, id) in roles" :value="role.id" :key="id">{{ role.text }}</option>
                                            </select>
                                            </div>
                                        </div>

                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.member_league') && getSiteProps('league.visibility')">
                                          <div class="form-group">
                                            <label class="form-label">League</label>
                                            <select class="form-control" id="league" v-model="searchForm.league">
                                              <option value="">Select League</option>
                                              <option v-for="(league, id) in leagues" :value="league.id" :key="id">{{ league.name }}</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.age_division') && this.getSiteProps('memberRegistration.team.fields.club_info.age_division')">
                                          <div class="form-group">
                                            <label class="form-label">Age Division</label>
                                            <select class="form-control" id="age_division" v-model="searchForm.age_division">
                                              <option value="">Select Age Division</option>
                                              <option v-for="(discipline, id) in disciplines" :value="discipline.id" :key="id">{{ discipline.text }}</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div v-if="this.getSiteProps('membership_reports.filters.team')" class="col-md-4">
                                          <div class="form-group">
                                            <label for="team_id" class="form-label">Team</label>
                                            <select class="form-control" id="team_id" v-model="searchForm.team_id">
                                              <option value="">Select Team</option>
                                              <option v-for="(TeamData,key) in teams" :key="key" :value="TeamData.id">
                                                {{ TeamData.text }}
                                              </option>
                                            </select>
                                            <span class="text-sm text-danger"></span>
                                          </div>
                                        </div>
                                        <div v-if="this.getSiteProps('membership_reports.filters.ncsa')" class="col-md-4">
                                          <div class="form-group">
                                            <label class="form-label">Recruiting</label>
                                            <select id="member_ncsa" class="form-control" v-model="searchForm.member_ncsa">
                                              <option value="">Select</option>
                                              <option value="1">Yes</option>
                                              <option value="0">No</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div v-if="this.getSiteProps('membership_reports.filters.citizenship')" class="col-md-4">
                                          <div class="form-group">
                                            <label class="form-label">Citizenship</label>
                                            <select id="citizenship" class="form-control" v-model="searchForm.citizenship">
                                              <option
                                                  v-for="listOfCountry in listOfCountries"
                                                  :key="listOfCountry.country">
                                                {{ listOfCountry.country }}
                                              </option>
                                            </select>
                                          </div>
                                        </div>

                                        <div class="col-md-4"  v-if="this.getSiteProps('membership_reports.filters.auto_renewal')">
                                            <div class="form-group">
                                                <label class="form-label">Auto Renewal</label>
                                                <select 
                                                    v-model="searchForm.auto_renewal" 
                                                    name="auto_renewal" 
                                                    id="auto_renewal" 
                                                    class="form-control"
                                                >
                                                 <option value=''>Select</option>
                                                <option value='ON'>ON</option>
                                                <option value='OFF'>OFF</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-label">Coupon Code</label>
                                                <Select2 v-model="searchForm.coupon_code" name="coupon_code" id="coupon_code" :options="couponcode" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                                            </div>
                                        </div>

                                      <div class="col-md-4"  v-if="this.subdomain=='shooting'">
                                        <div class="form-group">
                                          <label class="form-label">Veteran Status</label>
                                          <select
                                              v-model="searchForm.veteran_status"
                                              name="veteran_status"
                                              id="veteran_status"
                                              class="form-control"
                                          >
                                            <option value=''>Select</option>
                                            <option value='Yes'>Yes</option>
                                            <option value='No'>No</option>
                                          </select>
                                        </div>
                                      </div>

                                        <div class="col-md-12 modal-searchbtn text-center">
                                            <div class="form-group">
                                                <button type="submit" class="btn btn-primary btn-blue">Search</button>
                                            </div>
                                            <div class="text-sm text-danger">{{ errors.not_found }}</div>
                                        </div>
                                    </div>
                                </form>
                            </b-modal>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>
</template>

<script>
import $ from "jquery";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";

import moment from 'moment';
import DatePick from "vue-date-pick";
import axios from 'axios';
import fecha from 'fecha';

const validateName = name => {
    if (name.length || name != "") {
        return { valid: true, error: null };
    }
    return { valid: false, error: null };
};

export default {
    components: { },
    el:'#memreport',

    data() {
        return {
            searchValue:'',
            keyvalue:'',
            filtersApplied: false,
            exportProgress: false,
            states:[],
            countries:[],
            disciplines:[],
            diversities:[],
            safe_sport_statuses:[],
            ethnicities:[{id:'Hispanic or Latino', text:'Hispanic or Latino'},{id:'Non-Hispanic or Latino',text:'Non-Hispanic or Latino'},{id:'Prefer not to say',text:'Prefer not to say'},{id:'N/A',text:'N/A'}],
            disabilities:[{id:'Persons without Disability', text:'Persons without Disability'},{id:'Persons with Disability',text:'Persons with Disability'},{id:'Prefer not to say',text:'Prefer not to say'},{id:'N/A',text:'N/A'}],
            veterans:[{id:'Veteran', text:'Veteran'},{id:'Non-Veteran',text:'Non-Veteran'},{id:'Active or Reserve',text:'Active or Reserve'},{id:'Prefer not to say', text:'Prefer not to say'},{id:'N/A',text:'N/A'}],
            couponcode:[],
            searchForm: {
                printed_magazine:'',
                confirmation_code:'',
                first_name: '',
                last_name: '',
                email: '',
                discipline:[],
                safe_sport_status:[],
                registration_start_date: '',
                registration_end_date: '',
                expiration_start_date: '',
                expiration_end_date: '',
                Safe_Sport_expiration_start_date:'',
                Safe_Sport_expiration_end_date:'',
                dob_start_date: '',
                dob_end_date: '',
                from_age: '',
                to_age: '',
                transaction_start_date: '',
                transaction_end_date: '',
                registration_dob:'',
                gender: '',
                year: '',
                membership_id: [],
                status: '',
                transaction_id: '',
                groups:[],
                diversity:[],
                tags:'',
                volunteering_roles:'',
                region_name: '',
                state_id:[],
                country_id:[],
                home_federation:'',
                family_type:'',
                other_state:'',
                undeliverable_address:'',
                waiver_status:'',
                auto_renewal:'',
                ethnicity:'',
                disability:'',
                veteran_status:'',
                league:'',
                age_division:'',
                member_ncsa:'',
                season:'',
                citizenship:'',
                team_id:'',
                coupon_code:''
            },
            errors: {},
            groupList:[],
            federationList:[],
            GroupModel:'',
            tags:[],
            volunteering:false,
            roles:[],
            leagues:[],
            teams: [],
            team_id:'',
            seasons:[],
            isLeague:false
        };
    },
    methods: {
        countryChange(e) {
           if(this.searchForm.country_id.some(data => data == 'USA')&&this.searchForm.country_id.some(data => data == 'Canada'))
           {
            this.states = [];
            this.listOfStates.forEach(code => {
                this.states.push({ id: code['state_code']+' - '+code['state'] , text: code['state'] });
            });
            this.listOfCAStates.forEach(code => {
                this.states.push({ id: code['state_code']+' - '+code['state'] , text: code['state'] });
            });
           }
           else if(this.searchForm.country_id.some(data => data == 'USA'))
           {
            this.states = [];
            this.listOfStates.forEach(code => {
                this.states.push({ id: code['state_code']+' - '+code['state'] , text: code['state'] });
            });
           }
           else if(this.searchForm.country_id.some(data => data == 'Canada'))
           {
            this.states = [];
            this.listOfCAStates.forEach(code => {
                this.states.push({ id: code['state_code']+' - '+code['state'] , text: code['state'] });
            });
           }
        },

        getCoupon() {
        axios
            .get(this.basePath + "api/coupons/index")
            .then((response) => {
            let coupon = response.data.couponList;
            let coupon_codes = Object.keys(coupon).sort();
            coupon_codes.forEach(code => {
                this.couponcode.push({ id: code, text: code });

            });
            })        
            .catch(error => {this.errors = error.response.data.couponList});
        },

       queryForKeywords(event) {
            const value = event.target.value
         if(this.keyvalue == value){
           return;
         }else {
           this.keyvalue = value;
         }
            this.keywords = value
            if(!isNaN(value) && value != '' && value.length == 9 & this.subdomain == 'waterski'){
            this.searchForm.confirmation_code = value;
                // this.table.search(value).draw();
                console.log('if',value);
                this.advancedSearch();
            }else{
            this.searchForm.confirmation_code = '';
                console.log('else',value);
                this.table.search(value).draw();
            }
        },
        getMembership(){
          axios.get(this.basePath + "api/getMemberships/individual?order=a2z")
              .then((response) => {
                this.membershipData = response.data.data;
                delete this.membershipData[6]; //remove annual club or club active
                if(this.subdomain == 'shooting'){
                    delete this.membershipData[4]; //remove supporting membership
                    delete this.membershipData[9]; //remove 5 year club
                }
              })
              .catch(error=>{
                console.log("error getting Membership info")
              })
        },
        advancedSearch() {
            if(!this.validate()) {
                return;
            }
            this.table.draw();
            this.filtersApplied = true;
            this.$refs['modal-member-report'].hide();
        },
        validate(){
            this.errors = {};
            this.valid =  true;
            const validStartDate = validateName(this.searchForm.registration_start_date);
            this.errors.registration_start_date = validStartDate.error;

            const validEndDate = validateName(this.searchForm.registration_end_date);
            this.errors.registration_end_date = validEndDate.error;

            const validexpirationStartDate = validateName(this.searchForm.expiration_start_date);
            this.errors.expiration_start_date = validexpirationStartDate.error;

            const validexpirationEndDate = validateName(this.searchForm.expiration_end_date);
            this.errors.expiration_end_date = validexpirationEndDate.error;

            const validDobStartDate = validateName(this.searchForm.dob_start_date);
            this.errors.dob_start_date = validDobStartDate.error;

            const validDobEndDate = validateName(this.searchForm.dob_end_date);
            this.errors.dob_end_date = validDobEndDate.error;
            
            var dateValid = false;
            if(validStartDate.valid && !this.validateDate(this.searchForm.registration_start_date)){
                dateValid = true;
                this.errors.registration_start_date = "Invalid Date";
            }
            if(validEndDate.valid && !this.validateDate(this.searchForm.registration_end_date)){
                dateValid = true;
                this.errors.registration_end_date = "Invalid Date";
            }
            if(validexpirationStartDate.valid && !this.validateDate(this.searchForm.expiration_start_date)){
                dateValid = true;
                this.errors.expiration_start_date = "Invalid Date";
            }
            if(validexpirationEndDate.valid && !this.validateDate(this.searchForm.expiration_end_date)){
                dateValid = true;
                this.errors.expiration_end_date = "Invalid Date";
            }
            if(validDobStartDate.valid && !this.validateDate(this.searchForm.dob_start_date)){
                dateValid = true;
                this.errors.dob_start_date = "Invalid Date";
            }
            if(validDobEndDate.valid && !this.validateDate(this.searchForm.dob_end_date)){
                dateValid = true;
                this.errors.dob_end_date = "Invalid Date";
            }

            if(dateValid == false){
                if((validStartDate.valid && validEndDate.valid) && (moment(this.searchForm.registration_end_date).isBefore(this.searchForm.registration_start_date))) {
                    this.errors.registration_end_date = 'End date must be greater than Start date!';
                    return this.valid =  false;
                } else {
                    this.valid = true;
                }
            } else {
                this.valid = false;
            }

           
            if(moment(this.searchForm.expiration_end_date).isBefore(this.searchForm.expiration_start_date)) {
                this.errors.expiration_end_date = 'Expiration End date must be greater than Expiration Start date!';
                return this.valid =  false;
            } else {
                this.valid = true;
            }
            if(moment(this.searchForm.dob_end_date).isBefore(this.searchForm.dob_start_date)) {
                this.errors.dob_end_date = 'Dob End date must be greater than Dob Start date!';
                return this.valid =  false;
            } else {
                this.valid = true;
            }
            if(this.searchForm.from_age > this.searchForm.to_age){
             this.errors.to_age = 'To Age must be greater than From Age!';
                return this.valid =  false;
            } else {
                this.valid = true;
            }

            const validTStartDate = validateName(this.searchForm.transaction_start_date);
            this.errors.transaction_start_date = validTStartDate.error;

            const validTEndDate = validateName(this.searchForm.transaction_end_date);
            this.errors.transaction_end_date = validTEndDate.error;
            
            dateValid = false;
            if(validTStartDate.valid && !this.validateDate(this.searchForm.transaction_start_date)){
                dateValid = true;
                this.errors.transaction_start_date = "Invalid Date";
            }
            if(validTEndDate.valid && !this.validateDate(this.searchForm.transaction_end_date)){
                dateValid = true;
                this.errors.transaction_end_date = "Invalid Date";
            }

            if(dateValid == false){
                if((validTStartDate.valid && validTEndDate.valid) && (moment(this.searchForm.transaction_end_date).isBefore(this.searchForm.transaction_start_date))) {
                    this.errors.transaction_end_date = 'End date must be greater than Start date!';
                    return this.valid =  false;
                } else {
                    this.valid = true;
                }
            } else {
                this.valid = false;
            }

            if(this.searchForm.registration_dob != "" && !this.validateDate(this.searchForm.registration_dob)){
                this.errors.registration_dob = "Invalid Date";
                this.valid = false;
            }
          const validSafesportExpirationStartDate = validateName(this.searchForm.Safe_Sport_expiration_start_date);
          this.errors.Safe_Sport_expiration_start_date = validSafesportExpirationStartDate.error;

          const validsafeSportExpirationEndDate = validateName(this.searchForm.Safe_Sport_expiration_end_date);
          this.errors.Safe_Sport_expiration_end_date = validsafeSportExpirationEndDate.error;
          dateValid = false;
          if(validSafesportExpirationStartDate.valid && !this.validateDate(this.searchForm.Safe_Sport_expiration_start_date)){
            dateValid = true;
            this.errors.Safe_Sport_expiration_start_date = "Invalid Date";
          }
          if(validsafeSportExpirationEndDate.valid && !this.validateDate(this.searchForm.Safe_Sport_expiration_end_date)){
            dateValid = true;
            this.errors.Safe_Sport_expiration_end_date = "Invalid Date";
          }
          if(dateValid == false){
            if((validSafesportExpirationStartDate.valid && validsafeSportExpirationEndDate.valid) && (moment(this.searchForm.Safe_Sport_expiration_end_date).isBefore(this.searchForm.Safe_Sport_expiration_start_date))) {
              this.errors.Safe_Sport_expiration_end_date= 'Safe Sport Expiration End date must be greater than Safe Sport Expiration Start date!';
              return this.valid =  false;
            } else {
              this.valid = true;
            }
          }else {
            this.valid = false;
          }

            return this.valid;
        },
        refreshList(event){
            this.searchForm = {
                confirmation_code:'',
                first_name: '',
                last_name: '',
                email: '',
                registration_start_date: '',
                registration_end_date: '',
                expiration_start_date: '',
                expiration_end_date: '',
                Safe_Sport_expiration_start_date:'',
                Safe_Sport_expiration_end_date:'',
                dob_start_date: '',
                dob_end_date: '',
                from_age: '',
                to_age: '',
                state_id:[],
                country_id:[],
                discipline:[],
                safe_sport_status:[],
                transaction_start_date: '',
                transaction_end_date: '',
                registration_dob:'',
                gender: '',
                year: '',
                membership_id: [],
                status: '',
                transaction_id: '',
                region_name:'',
                home_federation:'',
                family_type:'',
                other_state:'',
                undeliverable_address:'',
                waiver_status:'',
                auto_renewal:'',
                ethnicity:'',
                disability:'',
                veteran_status:'',
                league:'',
                age_division:'',
                member_ncsa:'',
                season:'',
                citizenship:'',
                team_id:'',
                coupon_code:''
            };
            // this.table.clear().draw();
            this.table.ajax.reload();
            this.filtersApplied = false;
        },

        Search(event) {
          this.searchValue = this.$route.query.term;
          if(this.searchValue !== undefined) {
            this.searchValue = $("#globalSearch").val();
            const dataTable = $("#list_table").DataTable();
            dataTable.search(this.searchValue).draw();
          }
        },
        exportCsv(){
            var self = this;
            $.ajax({
                url: process.env.VUE_APP_URL + "api/reports/export/prep-member-registrations",
                type: 'post',
                "data": this.table.ajax.params(),
                beforeSend: function(){
                    self.exportProgress = true;
                },
                "success": function(res, status, xhr) {
                    self.exportProgress = false;
                    if(res.file_url != undefined) window.open(res.file_url, '_blank');
                },
                error: function(err){
                    console.log(err);
                    self.exportProgress = false;
                }
            });
        },
        getGroup()
      {
        axios.get(this.basePath+'api/getGroup')
            .then(response => {
              let federation_id = response.data.data;
              let federation_list = Object.keys(federation_id);
                
                if(federation_list.length ){        
                    federation_list.forEach(name => { 
                        this.federationList.push({ id: federation_id[name].name, text: federation_id[name].name });
                    });     
                }       
            })
            .catch(err => {
              // Manage the state of the application if the request
              // has failed
            })
      },
        getGroupList()
        {
            axios.get(this.basePath+'api/admin/group/list',{ headers:this.adminHeaders })
            .then((res) => {
                let groupList_id = res.data.data;                 
                let group_list = Object.keys(groupList_id);
                
                if(group_list.length ){        
                    group_list.forEach(id => { 
                        this.groupList.push({ id: groupList_id[id].id, text: groupList_id[id].name });
                    });     
                }       
            })
            .catch((error) => {
                console.log('Error:'+ error);               
            }).finally(() => {
            });
        },
        getTags()
        {
            axios.get(this.basePath+'api/admin/group/tags',{ headers:this.adminHeaders })
            .then((res) => {
                this.tags = res.data.data;  
            })
            .catch((error) => {
                console.log('Error:'+ error);               
            }).finally(() => {
            });
        },
        getVolunteeringRoles()
        {
            axios.get(this.basePath+'api/admin/volunteering/roles', { headers: this.adminHeaders })
                .then((res) => { 
                    this.roles = res.data.data;
                })
                .catch((error) => {
                    console.log('Error:'+ error);               
                    return false;            
                }).finally(() => {
                    
                }
            );  
        },
        getState(){
            this.listOfStates.forEach(code => {
                this.states.push({ id: code['state_code']+' - '+code['state'] , text: code['state'] });
            });    
        },
        getCountry(){
            this.listOfCountries.forEach(code => {
                this.countries.push({ id: code['country'] , text: code['country'] });
            });    
        },
        getDiscipline(){

        axios.get(this.basePath+"api/getDisciplines?order=a2z")
            .then(response => {
              let disc = response.data.data;
              let discipline_codes = Object.keys(disc).sort();
                if(this.getSiteProps('membership_reports.filters.primary_sport_discipline'))
                {
                  discipline_codes.forEach(code => {
                      if(code != "NP - No Preference"){
                          let discipline_code =  code.split(' - ');
                          this.disciplines.push({ id: discipline_code[0], text: discipline_code[0] == "NA - N/A" ? "N/A" : code });
                      }
                  });
                  this.disciplines.push({id: 8, text: "NP - No Preference"});
                }
                if(this.getSiteProps('membership_reports.filters.age_division'))
                {
                  discipline_codes.forEach(code => {
                    let discipline_code =  code.split(' - ');
                    this.disciplines.push({ id: disc[code] == "NA - N/A" ? "N/A" : disc[code], text: code == "NA - N/A" ? "N/A" : code });
                  });
                  console.log("age",this.disciplines);
                }
            })
            .catch(err => {
              // Manage the state of the application if the request
              // has failed
            })
        },
        getSafeSportStatus(){
        axios.get(this.basePath+"api/getSafeSportStatus?order=a2z")
            .then(response => {
              let safeSportStatus = response.data.data;
                // let statuses = [];
              let statuses = {
                "Not Started": 0,
                "In Progress": 1,
                "Complete": 3,
                "Expired": 13
              };
              let keys = Object.keys(statuses).sort();
            //   console.log('safeSportStatuses', safeSportStatus);
                keys.forEach(key => {
                  this.safe_sport_statuses.push({ id: statuses[key], text: key });
                });
            })
            .catch(err => {
              // Manage the state of the application if the request
              // has failed
            })
        },
        getTeam()
        {
          axios.get(this.basePath+'api/get_teams', { headers: this.adminHeaders })
              .then((res) => {
                this.teams = res.data.data;
              })
              .catch((error) => {
                console.log('Error:'+ error);
                return false;
              }).finally(() => {

              }
          );
        },
        getLeague()
        {
          axios.get(this.basePath+'api/league', { headers: this.adminHeaders })
              .then((res) => {
                this.leagues = res.data.data;
              })
              .catch((error) => {
                console.log('Error:'+ error);
                return false;
              }).finally(() => {

              }
          );
        },
        getSeason()
        {
          axios.get(this.basePath+'api/get_season', { headers: this.adminHeaders })
              .then((res) => {
                this.seasons = res.data.data;
              })
              .catch((error) => {
                console.log('Error:'+ error);
                return false;
              }).finally(() => {

              }
          );
        },
        getdiversities() {
          axios.get(this.basePath+"api/getDiversity").then(response => {
            // this.diversities = response.data.data;
            let diver = response.data.data;
                    let newDivers=[];
                    diver.map(key => {
                    newDivers.push({ id: key.id, text: key.name });
                });
                this.diversities = newDivers;
                this.diversities.push({id:99,text:'N/A'});

          }).catch(err => { console.log(err); });
		    }
    },
    mounted(){
        const retry = setInterval(() => {
            this.volunteering = this.getSiteProps('volunteering.visibility');
            if(this.volunteering)
            {
                this.getVolunteeringRoles();
            }
            if(this.volunteering != undefined) clearInterval(retry);
        }, 200);

        const retry2 = setInterval(() => {
            this.GroupModel = this.getSiteProps('group_model.visible');  
            if(this.GroupModel)
            {            
            this.getGroupList();
            this.getTags();
            }          
            if(this.GroupModel != undefined) clearInterval(retry2);
        }, 200);
        
        this.getGroup();  
        this.getState();
        this.getCountry();
        this.getDiscipline();
        this.getSafeSportStatus();
        this.getMembership();
        this.getMembershipStatus('a2z');
        this.getTeam();
        this.getdiversities();
        this.getCoupon();
        if(this.subdomain == 'alliancefastpitch')
        {
          this.getLeague();
          this.getSeason();
        }
      if(this.subdomain != 'alliancefastpitch') {
          this.getRegions();
        }
        var self = this;
        var statusText = this.subdomain == "shooting" ? 'waiver_status' : 'waiver_status_txt';
        var vetranStatus = this.subdomain == "shooting" ? 'veteran_status' : 'personal_details.veteran_status';
        this.table = $("#list_table").DataTable({
            dom: "lfrtip",
            processing: true,
            serverSide: true,
            bFilter: true,
            responsive: true,
            ajax: {
                url: process.env.VUE_APP_URL + "api/reports/member-registrations",
                type: 'post',
                dataType: 'json',
                data: function (d) {
                    d.confirmation_code = $('#confirmation_code').val() || self.searchForm.confirmation_code;
                    d.first_name = $('#first_name').val() || self.searchForm.first_name;
                    d.last_name = $('#last_name').val() || self.searchForm.last_name;
                    d.email = $('#email').val() || self.searchForm.email;
                    d.start_date = $('#registration_start_date').val() || self.searchForm.registration_start_date;
                    d.end_date = $('#registration_end_date').val() || self.searchForm.registration_end_date;
                    d.expiration_start_date = $('#expiration_start_date').val() || self.searchForm.expiration_start_date;
                    d.expiration_end_date = $('#expiration_end_date').val() || self.searchForm.expiration_end_date;
                    d.dob_start_date = $('#dob_start_date').val() || self.searchForm.dob_start_date;
                    d.dob_end_date = $('#dob_end_date').val() || self.searchForm.dob_end_date;
                    d.from_age = $('#from_age').val() || self.searchForm.from_age;
                    d.to_age = $('#to_age').val() || self.searchForm.to_age;
                    d.discipline = $('#discipline').val() || self.searchForm.discipline;
                    d.safe_sport_status = $('#safe_sport_status').val() || self.searchForm.safe_sport_status;
                    d.dob = $('#registration_dob').val() || self.searchForm.registration_dob;
                    d.gender = $('#gender').val() || self.searchForm.gender;
                    d.state_id = $('#state_id').val() || self.searchForm.state_id;
                    d.country_id = $('#country_id').val() || self.searchForm.country_id;
                    d.year = $("#year1").val() || self.searchForm.year;
                    d.membership_id = $('#membership_id').val() || self.searchForm.membership_id;
                    d.status = $('#status').val() || self.searchForm.status;
                    d.Safe_Sport_expiration_start_date = $('#Safe_Sport_expiration_start_date').val() || self.searchForm.Safe_Sport_expiration_start_date;
                    d.Safe_Sport_expiration_end_date = $('#Safe_Sport_expiration_end_date').val() || self.searchForm.Safe_Sport_expiration_end_date;
                    d.transaction_id = $('#transaction_id').val() || self.searchForm.transaction_id;
                    d.transaction_start_date = $("#transaction_start_date").val() || self.searchForm.transaction_start_date;
                    d.transaction_end_date = $("#transaction_end_date").val() || self.searchForm.transaction_end_date;
                    d.region_name = $('#region_name').val() || self.searchForm.region_name;
                    d.group = $('#groups').val() || self.searchForm.groups;
                    d.diversity = $('#diversity').val() || self.searchForm.diversity;
                    d.home_federation = $('#home_federation').val() || self.searchForm.home_federation;
                    d.bad_address = $('#undeliverable_address').val() || self.searchForm.undeliverable_address;
                    d.waiver_status = $('#waiver_status').val() || self.searchForm.waiver_status;
                    d.auto_renewal = $('#auto_renewal').val() || self.searchForm.auto_renewal;
                    d.ethnicity = $('#ethnicity').val() || self.searchForm.ethnicity;
                    d.disability = $('#disability').val() || self.searchForm.disability;
                    d.veteran_status = $('#veteran_status').val() || self.searchForm.veteran_status;
                    d.family_type = $('#family_type').val() || self.searchForm.family_type;
                    d.other_state = $("#other_state").val() ||self.searchForm.other_state;
                    d.tags = $('#tags').val() || self.searchForm.tags;
                    d.volunteering_roles = $('#volunteering_roles').val() || self.searchForm.volunteering_roles;
                    d.printed_magazine = $('#printed_magazine').val() || self.searchForm.printed_magazine;
                    d.league = $('#league').val() || self.searchForm.league;
                    d.age_division = $('#age_division').val() || self.searchForm.age_division;
                    d.team_id = $('#team_id').val() || self.searchForm.team_id;
                    d.member_ncsa = $('#member_ncsa').val() || self.searchForm.member_ncsa;
                    d.season = $('#season').val() || self.searchForm.season;
                    d.citizenship = $('#citizenship').val() || self.searchForm.citizenship;
                    d.coupon_code = $('#coupon_code').val() || self.searchForm.coupon_code;
                    d.search['value'] = d.search['value'].replace(/^0+/, '');
                }
            },
            columns: [
                { data: 'confirmation_code', name: 'confirmation_code', render: (data)=>{return data || "N/A";}},
                { data: "name", name: "name", render: (data)=>{return data || "N/A";} },
                { data: "email", name: "email", render: (data)=>{return data || "N/A";} },
                { data: "discipline", name: "discipline", orderable: true, render: (data)=>{return data || "N/A";}, visible: this.subdomain =="waterski", searchable: this.subdomain =="waterski" },
                { data: "state", name: "state", orderable: true, render: (data)=>{return data || "N/A";}, visible: this.subdomain  =="waterski", searchable: this.subdomain  =="waterski" },
                { data: "formattedDOB", name: "dob", render: (data)=>{return data || "N/A";} },
                { data: "member.printed_magazine", searchable:false, name: "Magazine Subscription", orderable:false, render: (data)=>{return (data == 0 || data == null)?'Yes':'No' || "N/A"} },
                { data: "membership_name", name: "membership_name", render: (data)=>{return data || "N/A";} },
                { data: "formattedStartDate", name: "start_date", render: (data)=>{return data || "N/A";} },
                { data: "formattedValidThru", name: "valid_thru", render: (data)=>{return data || "N/A";} },
                { data: "auto_renewal", name: "auto_renewal", render: (data)=>{return data || "N/A";}, visible: this.subdomain != "alliancefastpitch" },
                { data: "status_txt", name: "status_txt", render: (data)=>{return data || "N/A";} },
                { data: "transaction_id", name: "transaction_id", render: (data)=>{return data || "N/A";} },
                { data: "formattedTransactionDate", name: "transaction_date", render: (data)=>{return data || "N/A";} },
                { data: "groups", name: "groups", render: (data)=>{return data || "N/A";}},
                { data: "tags", name: "tags", render: (data)=>{return data || "N/A";}},
                { data: "volunteering", name: "volunteering", render: (data)=>{return data || "N/A"} },
                { data: "contact_details.bad_address", name: "bad_address", render: (data)=>{return (data == 1 || data == null)?'Yes':'No' || "N/A"} },
                { data: "team_id", name: "activeTeam.name", render: (data)=>{return data || "N/A"},  visible: this.subdomain == "alliancefastpitch", searchable: false },
                { data: "team_name", name: "activeTeam.name", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch", searchable: false },
                { data: "member_league", name: "activeTeam.member_league", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch", searchable: false },
                { data: "age_division", name: "activeTeam.age_division", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch", searchable: false },
                { data: "membership_benefits", name: "personal_details.membership_benefits", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch", searchable: false },
                { data: "citizenship", name: "citizenship", render: (data)=>{return data || "N/A"},  visible: this.subdomain == "alliancefastpitch" },
                { data: statusText , name: "waiver_status", render: (data)=>{return data==null?"N/A":data}, searchable : this.subdomain != "alliancefastpitch" },
                { data: "waiver_signed_at", name: "valid_thru", render: (data)=>{return data==null?"N/A":data}, },
                { data: "waiver_expired_at", name: "valid_thru", render: (data)=>{return data==null?"N/A":data},},
                { data: "coupon_code", name: "coupon_code", render: (data)=>{return data==null?"N/A":data},searchable : true,visible : true },
                { data: vetranStatus, name: "veteran_status", render: (data)=>{return data==null?"N/A":data},searchable : this.subdomain == "shooting", visible: this.subdomain == "shooting" },

                { data: "actions", name: "actions", orderable: false, render: (data,type,row,meta) => {
                    let btn = '';
                    if(this.getSiteProps('general.scoring')){
                        btn += `<a class="primary-text" href="/admin/member/${row.confirmation_code}/score/${row.member_id}" title="Shooting Score"><i class="fas fa-trophy"></i></a> `
                    }
                    if(row.membership_id == 8 && this.subdomain == "shooting")        
                        btn += `<a class="view-profile-link" href="/admin/fanclub-registrations/${row.member_id}"><i class="fas fa-eye"></i></a>`
                    else if(row.membership_id == 10 && this.subdomain == "shooting")
                        btn += `<a class="view-profile-link" href="/admin/coach-registrations/${row.member_id}"><i class="fas fa-eye"></i></a>`
                    else if(this.subdomain=='shooting'&&row.member_type_id == 7)
                      btn += `<a class="view-profile-link" href="/admin/fan-registrations/${row.member_id}"><i class="fas fa-eye"></i></a>`
                    else if(this.subdomain=='waterski'&&row.member_type_id == 7)
                      btn += `<a class="view-profile-link" href="/admin/campski-registrations/${row.member_id}"><i class="fas fa-eye"></i></a>`
                    else
                        btn += `<a class="view-profile-link" href="/admin/registrations/${row.member_id}"><i class="fas fa-eye"></i></a>` 
                    return btn
                  }
                }
            ],
            "order": [[ 13, "desc" ]],
          columnDefs: [
            { targets: [14,15,16,24,25,26], visible: this.subdomain=='shooting' },
            { targets: [12], visible: this.subdomain !== 'waterski' },
            { targets: [6,17,27], visible: false},

          ],
        });

      this.Search();
    },
    computed: {
        rows() {
            return this.items.length
        },
        searchTerm() {
          return this.$route.query.term;
        },
        years () {
          let currentYear = new Date().getFullYear();
          let yearsArray = [];
          for (let year = currentYear + 1; year >= 2018; year--) {
            yearsArray.push(year);
          }
          return yearsArray;
        },
        regionsListSelect2(){
            var keys = Object.keys(this.regionsList);
            return keys.map(item => {
                return { id: this.regionsList[item].code, text: this.regionsList[item].name };
            })
        },
        statusListSelect2(){
            var list = [];
            var keys = Object.keys(this.membershipStatus).sort();
            keys.map(key => {
                list.push({ id: this.membershipStatus[key], text: key })
            });
            return list;
        },
        membershipListSelect2(){
            var keys = Object.keys(this.membershipData);
            return keys.map(key => {
                return { id: this.membershipData[key], text: key }
            });
        },
    },
    watch: {
      searchTerm(newValue) {
        if (newValue !== '') {
          this.Search();
        }
      }
    }
}
</script>